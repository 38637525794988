@import "variables";
@import "mixins/icon";
.al-color {
  &.white {
    color: $white;
  }

  &.neon-yellow {
    color: $neon-yellow;
  }


  &.light-periwinkle-two {
    color: $light-periwinkle-two;
  }

  &.blue-100 {
    color: $blue-100;
  }

  &.blue-300 {
    color: $blue-300;
  }

  &.blue-400 {
    color: $blue-400;
  }

  &.blue-800 {
    color: $blue-800;
  }

  &.error-dark{
    color: $error-dark
  }

  &.warn-dark{
    color: #ffba00;
  }

}
.al-icon, .icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  &.flashing-icon {
    transition: opacity 0.3s;
  }

  &.flashing-icon:hover {
    opacity: 1 !important;
  }

  &.white {
    filter: brightness(0) contrast(1) grayscale(1) invert(1);
  }

  &.xs {
    width: 10px;
    height: 10px;
    font-size: 10px;
  }
  &.msm{
    width: 13px;
    height: 13px;
    font-size: 13px;
  }

  &.sm {
    width: 16px;
    min-width: 16px;
    height: 16px;
    font-size: 16px;
  }

  &.sm-md {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  &.md {
    width: 28px;
    height: 28px;
    font-size: 28px;
  }

  &.lg {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }
  &.l-40{
    width: 40px;
    height: 40px;
    font-size: 40px;
  }

  &.xl {
    width: 48px;
    height: 48px;
    font-size: 48px;
  }

  &.xxl {
    width: 64px;
    height: 64px;
    font-size: 64px;
  }
}

.al-icon {
  text-align: center;
  display: inline-block;
}

// no font icon
.i-slack-mark-color {
  @include icon('#{$assets-icons-path}/slack-mark-color.svg');
}

// no font icon
.i-expand-collapse-all {
  @include icon('#{$assets-icons-path}/expand-collapse-all.svg');
}

// no font icon
.i-expand-collapse-all-inactive {
  @include icon('#{$assets-icons-path}/expand-collapse-inactive.svg');
}

// no font icon
.i-dataview {
  @include icon('#{$assets-icons-path}/dataview-icon.svg');
}

.i-clone {
  @include icon('#{$assets-icons-path}/clone.svg');
}

.i-clone-black {
  @include icon('#{$assets-icons-path}/clone-black.svg');
}

.i-extand-copy-1 {
  @include icon('#{$assets-icons-path}/extand-copy-1.svg');
}

.i-home-off {
  @include icon('#{$assets-icons-path}/home-off.svg');
}

.i-list-view {
  @include icon('#{$assets-icons-path}/bars-menu.svg');
}

.i-plus-blue {
  @include icon('#{$assets-icons-path}/plus-blue.svg');
}

.i-projects-off {
  @include icon('#{$assets-icons-path}/projects-off.svg');
}

.i-trash {
  @include icon('#{$assets-icons-path}/trash.svg');

  &.menu {
    @include icon('#{$assets-icons-path}/trash-menu.svg');

  }
}

.i-compare-copy {
  @include icon('#{$assets-icons-path}/compare-copy.svg');
}


//Problem converting to font
.i-extand-black {
  @include icon('#{$assets-icons-path}/extand-black.svg');
}

//we should have one sort icon with colors
.i-sort-off {
  @include icon('#{$assets-icons-path}/sort-off.svg');
}

//no font
.i-plus-thin {
  @include icon('#{$assets-icons-path}/plus-thin.svg');
}

// font icon is not good (more than one color)
//.i-queues-white {
//  @include icon('#{$assets-icons-path}/queues-white.svg');
//}

//.i-queue {
//  @include icon('#{$assets-icons-path}/queue.svg');
//}

//Two colors
//.i-queues-icon {
//  @include icon('#{$assets-icons-path}/queues-icon.svg');
//}

//sort icon
.i-sort-on-down {
  @include icon('#{$assets-icons-path}/sort-on-down.svg');
}

.i-draft-light-blue {
  @include icon('#{$assets-icons-path}/draft-light-blue.svg');
}

.i-edit {
  @include icon('#{$assets-icons-path}/edit.svg');

  &.menu {
    @include icon('#{$assets-icons-path}/edit-menu.svg');
  }
}

.i-filter-off {
  opacity: 0.3;
  @include icon('#{$assets-icons-path}/filter-off.svg');
}

.i-how-to-white {
  @include icon('#{$assets-icons-path}/how-to-white.svg');
}

.i-move-to {
  @include icon('#{$assets-icons-path}/move-to.svg');
}

.i-merge-into {
  @include icon('#{$assets-icons-path}/merge-into.svg');

  &.menu {
    @include icon('#{$assets-icons-path}/merge-into-menu.svg');
  }
}


.i-move-to-project {
  @include icon('#{$assets-icons-path}/move-to-project.svg');
}

.i-queues {
  @include icon('#{$assets-icons-path}/queues.svg');
}

// no font icon
.i-sort-on-up {
  @include icon('#{$assets-icons-path}/sort-on-up.svg');
}

//not used in trains
.i-gear {
  @include icon('#{$assets-icons-path}/settings_gear.svg');
}

// two colors
.i-filter-on {
  @include icon('#{$assets-icons-path}/filter-on.svg');
}

// no status font icons
.i-queued {
  @include icon('#{$assets-icons-path}/pending.svg');

  &.bw {
    @include icon('#{$assets-icons-path}/pending-dark.svg');

  }
}

.i-in_progress, .i-Uploading {
  @include icon('#{$assets-icons-path}/running-green.svg');

  &.bw {
    @include icon('#{$assets-icons-path}/running-title.svg');
  }

  &.dark {
    @include icon('#{$assets-icons-path}/running-dark.svg');
  }
}

.i-completed, .i-Final {
  @include icon('#{$assets-icons-path}/completed.svg');

  &.bw {
    @include icon('#{$assets-icons-path}/completed-white.svg');
  }
}

.i-stopped {
  @include icon('#{$assets-icons-path}/completed.svg');

  &.bw {
    @include icon('#{$assets-icons-path}/completed-white.svg');
  }
}

.i-dequeu {
  @include icon('#{$assets-icons-path}/dequeue.svg');
}

.i-closed {
  @include icon('#{$assets-icons-path}/completed.svg');
}

.i-failed {
  @include icon('#{$assets-icons-path}/failed.svg');

  &.bw {
    @include icon('#{$assets-icons-path}/failed-white.svg');
  }
}

.i-published {
  @include icon('#{$assets-icons-path}/published-neon.svg');

  &.bw {
    @include icon('#{$assets-icons-path}/published-title.svg');
  }

}

.i-created {
  @include icon('#{$assets-icons-path}/draft-light-blue.svg');
}


// no font icon
.i-id {
  @include icon('#{$assets-icons-path}/id.svg');
}

// no font icon
.i-id-fix {
  @include icon('#{$assets-icons-path}/id-fix.svg');
}

// two colors
.i-annotation {
  @include icon('#{$assets-icons-path}/annotation.svg');
}

.i-annotation-white {
  @include icon('#{$assets-icons-path}/annotation-white.svg');
}

// no font icon
.i-v-neon {
  @include icon('#{$assets-icons-path}/v.svg');
}

// no font icon
.i-v-green {
  @include icon('#{$assets-icons-path}/v-green.svg');
}

// no font icon
.i-v {
  @include icon('#{$assets-icons-path}/v-copy.svg');
}

// no font icon
.i-v-white {
  @include icon('#{$assets-icons-path}/white-v.svg');
}

// no font icon
.i-v-black {
  @include icon('#{$assets-icons-path}/black-v.svg');
}

// two colors
.i-alert {
  @include icon('#{$assets-icons-path}/alert.svg');
}

.i-annotation-blue-neon {
  @include icon('#{$assets-icons-path}/annotation-blue-neon.svg');
}

// No font icon
.i-terms {
  @include icon('#{$assets-icons-path}/terms-icon.svg');
}

// Icon font is only one color
.i-model {
  @include icon('#{$assets-icons-path}/model.svg');
}


// Font icon looks bad
.i-alert-purple {
  @include icon('#{$assets-icons-path}/alert-purple.svg');
}

// not used in trains
.i-alert-red {
  @include icon('#{$assets-icons-path}/alert-red.svg');
}

// No font icon
.i-output-model {
  @include icon('#{$assets-icons-path}/output-model.svg');
}

// no font icon
.i-input-model {
  @include icon('#{$assets-icons-path}/input-model.svg');
}


.i-cat {
  @include icon('#{$assets-icons-path}/cat-icon.svg');
}


.i-Caffe {
  @include icon('#{$assets-icons-path}/caffe-icon.svg');
}

.i-PyTorch {
  @include icon('#{$assets-icons-path}/pytorch-icon.svg');
}

// No used in trains
.i-Custom {
  @include icon('#{$assets-icons-path}/custom-icon.svg');
}

//No font icon
.i-Keras {
  @include icon('#{$assets-icons-path}/keras-icon.svg');
}

.i-ScikitLearn {
  @include icon('#{$assets-icons-path}/scikit_learn_logo.svg');
}

//No font icon
.i-Tensor.Flow {
  @include icon('#{$assets-icons-path}/tensorflow-icon.svg');
  filter: none;
}

//No font icon
.i-no-plots {
  @include icon('#{$assets-icons-path}/no-plots.svg');
}

//No font icon dark-them
.i-no-plots-dark {
  @include icon('#{$assets-icons-path}/no-plots-dk.svg');
}

//No font icon
.i-no-log {
  @include icon('#{$assets-icons-path}/no-log.svg');
}

//No font icon dark-theme
.i-no-log-dark {
  @include icon('#{$assets-icons-path}/no-log-dk.svg');
}

//No font icon dark-theme
.i-no-code-dark {
  @include icon('#{$assets-icons-path}/no-code-dk.svg');
}

//No font icon
.i-no-debug {
  @include icon('#{$assets-icons-path}/no-debug.svg');
}

// No used in trains
.i-no-debug-white {
  @include icon('#{$assets-icons-path}/no-debug-white.svg');
}

.i-no-data-artifacts {
  @include icon('#{$assets-icons-path}/no-artifacts.svg');
}

.i-kibana {
  @include icon('#{$assets-icons-path}/kibana.svg');
}

.i-download-frames {
  @include icon('#{$assets-icons-path}/download-frames.svg');
}

// No font icon
.i-audio {
  @include icon('#{$assets-icons-path}/audio.svg');
}

//No font icon
.i-no-debug-samples {
  @include icon('#{$assets-icons-path}/no-debug-samples.svg');
}

//No font icon
.i-no-debug-samples-with-text {
  @include icon('#{$assets-icons-path}/no-debug-samples-with-text.svg');
}

.i-move-to-project {
  @include icon('#{$assets-icons-path}/move-to-project.svg');
}

.i-google {
  @include icon('#{$assets-icons-path}/ico-google.svg');
}

.i-github {
  @include icon('#{$assets-icons-path}/ico-github.svg');
}

.i-bitbucket {
  @include icon('#{$assets-icons-path}/ico-bitbucket.svg');
}

.i-microsoft {
  @include icon('#{$assets-icons-path}/ico-microsoft.svg');
}

.i-project-tags-filter-active {
  @include icon('#{$assets-icons-path}/project-tags-filter-active.svg');
}

.i-project-tags-filter {
  @include icon('#{$assets-icons-path}/project-tags-filter.svg');
}

.i-markdown {
  @include icon('#{$assets-icons-path}/ico-markdown.svg');
}

.i-c-logo {
  @include icon('#{$assets-icons-path}/c-logomark.svg');
}

.i-pipeline-empty-state {
  @include icon('#{$assets-icons-path}/pipeline-empty-state.svg');
}

.i-datasets-empty-state {
  @include icon('#{$assets-icons-path}/datasets-empty-state.svg');
}
